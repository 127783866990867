<template>
  <div class="divTable greenTable">
    <div class="divTableHeading divTableHeading-V-Table">
      <div class="divTableRow">
        <div class="divTableHead divTableHead--white">Month</div>
        <div class="divTableHead">measured wind speed</div>
        <div class="divTableHead">wind speed log. (m/s)</div>
        <div class="divTableHead">Skalierungsfaktor A (m/s)</div>
        <div class="divTableHead">Formfaktor k</div>
      </div>
    </div>
    <div class="divTableBody">
      <div
        class="divTableRow divTableRow--V-Table"
        v-for="e in weibulldistribution"
        v-bind:key="e.month"
      >
        <div class="divTableIDS">{{ e.month }}</div>
        <div class="divTableCell">
          {{ formatNum(e.measuredWindSpeed) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.windspeedLog) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.skalierungsfaktor) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.formfaktorK) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { formatNum } from "../helper/formatNumber";

export default {
  name: "WeibullDistributionTable",
  setup() {
    const store = useStore();
    const weibulldistribution = ref([0, 0, 0, 0, 0, 0, 0, 0]);
    const calculation = computed(() => store.getters.calculation);
    function calculateRows() {
      for (let i = 0; i < 12; i++) {
        let months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let month = months[i];
        weibulldistribution.value[i] = {
          month: month,
          measuredWindSpeed:
            Math.round(
              calculation.value.weibullDistrution.measuredWindSpeed[i] * 100
            ) / 100,

          windspeedLog:
            Math.round(
              calculation.value.weibullDistrution.windspeedLog[i] * 100
            ) / 100,
          skalierungsfaktor:
            Math.round(
              calculation.value.weibullDistrution.skalierungsfaktor[i] * 100
            ) / 100,
          formfaktorK: calculation.value.weibullDistrution.formfaktorK,
        };
      }
    }
    watch(calculation, () => {
      if (calculation.value.airDensityCalculor) {
        calculateRows();
      }
    });
    if (calculation.value.airDensityCalculor) {
      calculateRows();
    }
    return {
      weibulldistribution,
      formatNum,
    };
  },
};
</script>

<style></style>
