<template>
  <div class="divTable greenTable">
    <div class="divTableHeading divTableHeading-V-Table">
      <div class="divTableRow--dashboard">
        <div class="divTableHead">m/s avg.</div>
        <div class="divTableHead">Production p.a</div>
        <div class="divTableHead">Capacity factor</div>
        <div class="divTableHead">Full load hours</div>
        <div class="divTableHead">Equity tot</div>
        <div class="divTableHead">Loan tot</div>
        <div class="divTableHead">DSCR</div>
        <div class="divTableHead">OPEX p.a</div>
        <div class="divTableHead">CAPEX tot</div>
        <div class="divTableHead">RoE</div>
        <div class="divTableHead">LCOE</div>
      </div>
    </div>
    <div class="divTableBody">
      <div class="divTableRow divTableRow--V-Table-dashboard">
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.windspeed *
                  1000
              ) / 1000
            )
          }}
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.productionPA *
                  100
              ) / 100
            )
          }}
          kW
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                (this.$store.getters.calculation.dashboard?.cards
                  .fullLoadHours /
                  8760) *
                  10000
              ) / 100
            )
          }}%
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.fullLoadHours *
                  100
              ) / 100
            )
          }}
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.equityTotal *
                  100
              ) / 100
            )
          }}€
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.loanTotal * 100
              ) / 100
            )
          }}€
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.dscr * 100
              ) / 100
            )
          }}
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.opexPA * 100
              ) / 100
            )
          }}€
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.CapexTotal *
                  100
              ) / 100
            )
          }}€
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                (this.$store.getters.profitData[
                  this.$store.getters.profitData.length - 1
                ] /
                  this.$store.getters.calculation.dashboard?.cards
                    .equityTotal) *
                  10000
              ) / 100
            )
          }}
          %
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.cards.lcoe * 10000
              ) / 10000
            )
          }}€
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNum } from "../helper/formatNumber";

export default {
  setup() {
    return {
      formatNum,
    };
  },
};
</script>

<style></style>
