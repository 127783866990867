<template>
  <div class="divTable greenTable">
    <div class="divTableHeading divTableHeading-V-Table">
      <div class="divTableRow">
        <div class="divTableHead">Month</div>
        <div class="divTableHead">P50</div>
        <div class="divTableHead">P75</div>
        <div class="divTableHead">P90</div>
      </div>
    </div>
    <div class="divTableBody">
      <div
        class="divTableRow divTableRow--V-Table"
        v-for="e in airdensitycalculator"
        v-bind:key="e.month"
      >
        <div class="divTableIDS">{{ e.month }}</div>
        <div class="divTableCell">
          {{ formatNum(e.p50) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.p75) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.p90) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { formatNum } from "../helper/formatNumber";

// @ is an alias to /src
export default {
  name: "MonthlyOutputTable",
  setup() {
    const store = useStore();
    const airdensitycalculator = ref([0, 0, 0, 0, 0, 0, 0, 0]);
    const calculation = computed(() => store.getters.calculation);
    function calculateRows() {
      for (let i = 0; i < 12; i++) {
        let months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let month = months[i];
        airdensitycalculator.value[i] = {
          month: month,
          p50: Math.round(calculation.value.P50[i] * 100) / 100,

          p75: Math.round(calculation.value.P75[i] * 100) / 100,
          p90: Math.round(calculation.value.P90[i] * 100) / 100,
        };
      }
    }
    watch(calculation, () => {
      if (calculation.value.airDensityCalculor) {
        calculateRows();
      }
    });
    if (calculation.value.airDensityCalculor) {
      calculateRows();
    }
    return {
      airdensitycalculator,
      formatNum,
    };
  },
};
</script>

<style></style>
