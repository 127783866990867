<template>
  <div class="sectionContainer">
    <div class="section">
      <h1 class="header green">Overview</h1>
      <DashboardTechnicalTable></DashboardTechnicalTable>
      <DashboardFinancialTable></DashboardFinancialTable>
      <h1 class="header green">Financial</h1>
      <InterestChart></InterestChart>
      <ProfitChart></ProfitChart>
      <CardTable class="pagebreak"></CardTable>
      <h1 class="header green">Windspeed</h1>
      <MeasuredWindSpeedChart></MeasuredWindSpeedChart>
      <WindSpeedCalculationTable></WindSpeedCalculationTable>
      <AirDensityTable class="pagebreak"></AirDensityTable>
      <WeibullDistrubitionTable></WeibullDistrubitionTable>
      <h1 class="header pagebreak green">Output</h1>
      <MonthlyOutputChart></MonthlyOutputChart>
      <MonthlyOutputTable></MonthlyOutputTable>
      <CPValue></CPValue>
      <img :src="windRoseImg" v-if="windRoseImg" />
      <h1 class="header pagebreak green">Profitability</h1>
      <ProfitablilityTable :showAmount="1"></ProfitablilityTable>
      <ProfitablilityTable
        :showAmount="6"
        class="pagebreak"
      ></ProfitablilityTable>
      <ProfitablilityTable
        :showAmount="11"
        class="pagebreak"
      ></ProfitablilityTable>
      <ProfitablilityTable
        :showAmount="16"
        class="pagebreak"
      ></ProfitablilityTable>
      <ProfitablilityTable
        :showAmount="21"
        class="pagebreak"
      ></ProfitablilityTable>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DashboardTechnicalTable from "@/components/DashboardTechnicalTable.vue";
import DashboardFinancialTable from "@/components/DashboardFinancialTable.vue";
import InterestChart from "@/components/InterestChart.vue";
import MeasuredWindSpeedChart from "@/components/MeasuredWindSpeedChart.vue";
import ProfitChart from "@/components/ProfitChart.vue";
import MonthlyOutputChart from "@/components/MonthlyOutputChart.vue";
import WindSpeedCalculationTable from "@/components/WindSpeedCalculationTable.vue";
import CPValue from "@/components/CPValue.vue";
import AirDensityTable from "@/components/AirDensityTable.vue";
import WeibullDistrubitionTable from "@/components/WeibullDistrubitionTable.vue";
import ProfitablilityTable from "@/components/ProfitablilityTable.vue";
import MonthlyOutputTable from "@/components/MonthlyOutputTable.vue";
import CardTable from "@/components/CardTable.vue";
const ServerURL = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";
import { nextTick, onMounted, ref, computed } from "vue";
import router from "@/router";
import { useStore } from "vuex";
export default {
  name: "CreateReport",
  components: {
    DashboardTechnicalTable,
    DashboardFinancialTable,
    InterestChart,
    MeasuredWindSpeedChart,
    ProfitChart,
    MonthlyOutputChart,
    WindSpeedCalculationTable,
    CPValue,
    AirDensityTable,
    WeibullDistrubitionTable,
    ProfitablilityTable,
    MonthlyOutputTable,
    CardTable,
  },
  setup() {
    const store = useStore();
    const calculation = computed(() => store.getters.calculation);
    const windRoseImg = ref(null);
    function printPage() {
      store.dispatch("setShowLoadingScreen", true);
      nextTick(() => {
        axios
          .post(ServerURL + "/getWindRose", {
            ws: calculation.value.windspeedCalculator.windRose.ws,
            wd: calculation.value.windspeedCalculator.windRose.wd,
          })
          .then((response) => response.data)
          .then((returnValue) => {
            windRoseImg.value =
              "data:image/jpeg;base64," + returnValue.windRose;
            store.dispatch("setShowLoadingScreen", false);
            setTimeout(() => {
              window.print();
              router.go(-1);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            store.dispatch("setShowLoadingScreen", false);
            router.go(-1);
          });
        console.log("would have printed now");
      });
    }
    onMounted(() => {
      printPage();
    });
    return {
      windRoseImg,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.divTable.greenTable .divTableCell),
:deep(.divTable.greenTable .divTableHead),
:deep(.divTable.greenTable .divTableIDS) {
  padding: 0 !important;
}
.chartWrapper {
  width: 500px;
}

.section > div {
  margin-top: 10px;
}

.chartWrapper {
  box-shadow: unset !important;
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}
</style>
