<template>
  <div class="chartWrapper">
    <apexchart
      class="pChart"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { formatNum } from "../helper/formatNumber";

export default {
  name: "MonthlyOutputChart",
  setup() {
    const store = useStore();
    const calculation = computed(() => store.getters.calculation);
    const series = computed(() => [
      {
        name: "P50",
        data: calculation.value.P50 || [0, 0, 0],
      },
      {
        name: "P75",
        data: calculation.value.P75 || [0, 0, 0],
      },
      {
        name: "P90",
        data: calculation.value.P90 || [0, 0, 0],
      },
    ]);

    return {
      series,
      options: {
        chart: {
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.1,
          },
          toolbar: {
            show: true,
            offsetX: -5,
            offsetY: 5,
          },
        },
        colors: ["#00f0e5", "#008764", "#00bae3"],
        title: {
          text: "P-Szenario",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.7,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Monthly Output",
          },
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: formatNum,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -10,
          offsetX: -5,
        },
      },
    };
  },
};
</script>

<style></style>
