<template>
  <div class="divTable greenTable">
    <div class="divTableHeading divTableHeading-V-Table">
      <div class="divTableRow">
        <div class="divTableHead divTableHead--white">Month</div>
        <div class="divTableHead">temperature meas. height</div>
        <div class="divTableHead">rel air humidity</div>
        <div class="divTableHead">air pressure in hPa MSL</div>
        <div class="divTableHead">specific air density</div>
      </div>
    </div>
    <div class="divTableBody">
      <div
        class="divTableRow divTableRow--V-Table"
        v-for="e in airdensitycalculator"
        v-bind:key="e.month"
      >
        <div class="divTableIDS">{{ e.month }}</div>
        <div class="divTableCell">
          {{ formatNum(e.tempMeasHeight) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.relAirHumidity) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.airPressureInhPA) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.specAirDensity) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { formatNum } from "../helper/formatNumber";

// @ is an alias to /src
export default {
  name: "AirDensityTable",
  setup() {
    const store = useStore();
    const airdensitycalculator = ref([0, 0, 0, 0, 0, 0, 0, 0]);
    const calculation = computed(() => store.getters.calculation);
    function calculateRows() {
      for (let i = 0; i < 12; i++) {
        let months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let month = months[i];
        airdensitycalculator.value[i] = {
          month: month,
          tempMeasHeight:
            Math.round(
              calculation.value.airDensityCalculor.tempMeasHeight[i] * 100
            ) / 100,

          relAirHumidity:
            Math.round(
              calculation.value.airDensityCalculor.relAirHumidity[i] * 100
            ) / 100,
          airPressureInhPA:
            Math.round(
              calculation.value.airDensityCalculor.airPressureInhPA[i] * 100
            ) / 100,
          specAirDensity:
            Math.round(
              calculation.value.airDensityCalculor.specAirDensity[i] * 100
            ) / 100,
        };
      }
    }
    watch(calculation, () => {
      if (calculation.value.airDensityCalculor) {
        calculateRows();
      }
    });
    if (calculation.value.airDensityCalculor) {
      calculateRows();
    }
    return {
      airdensitycalculator,
      formatNum,
    };
  },
};
</script>

<style></style>
